@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

/*
.px-5 {
    padding-left: 84px !important;
    padding-right: 84px !important;
    @media (max-width: 375px) {
        padding-left: 42px !important;
        padding-right: 42px !important;
    }
}

.mx-n5 {
    margin-left: -84px !important;
    margin-right: -84px !important;
}
*/
.banner {
    background: $primary-400;
    width: 100%;
    height: 48px;
}
.brands-carousel * {
    border: 0px !important;
    outline: none !important;
}
.brands-carousel-card {
    @include media-breakpoint-only(xm) {
        height: 120px !important;
        width: 120px !important;
    }
    @include media-breakpoint-only(sm) {
        height: 140px !important;
        width: 140px !important;
    }
    @include media-breakpoint-only(md) {
        height: 160px !important;
        width: 160px !important;
    }
    @include media-breakpoint-only(lg) {
        height: 180px !important;
        width: 180px !important;
    }
    @include media-breakpoint-only(xl) {
        height: 200px !important;
        width: 200px !important;
    }
}
.posts {
    column-gap: 2.5rem;
}
.posts .card {
    box-shadow: none;
    border: none;
}
